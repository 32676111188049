//
// Layouts
//

// Navbar

.navbar {
	color: $white;
	background-color: $primary;
	
	.navbar-brand {
		margin-left: auto;
		margin-right: auto;
		padding-left: $navbar-toggler-width;
		color: $white;
	}
}

.navbar-collapse {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	background-color: $white;
}

.navbar-nav {
	.nav-item {
		margin-bottom: $paragraph-margin-bottom * .5;
	}
	
	.nav-link {
		border-bottom: solid $border-width $border-color;
		
		&:not(:hover) {
			color: $body-color;
		}
		
		&:hover {
			color: $link-hover-color;
		}
	}
}

@include media-breakpoint-up(md) {
	.navbar {
		padding-top: $navbar-padding-y * 2;
		padding-bottom: $navbar-padding-y * 2;
	}
	
	.navbar-toggler {
		display: none;
	}
	
	.navbar-collapse {
		display: block !important;
		
		> .container,
		> .container-fluid {
			display: flex;
			justify-content: space-between;
		}
	}
	
	.navbar-nav {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		
		.nav-item {
			margin-bottom: 0;
			text-align: center;
		}
		
		.nav-link {
			position: relative;
			padding: 0 $nav-link-padding-x;
			border-bottom: 0;
			border-right: solid $border-width $border-color;
			
			&:first-child {
				border-left: solid $border-width $border-color;
			}
			
			&::before,
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -10px;
				width: 0;
				height: 2px;
				background-color: $link-hover-color;
				@include transition($transition-base);
				transform: translateX(-50%);
			}
			
			&:hover::before,
			&:hover::after {
				width: 80%;
			}
		}
	}
}

// Footer

.colophon {
	padding-top: $navbar-padding-y * 2;
	padding-bottom: $navbar-padding-y * 2;
	@include font-size($font-size-sm);
	
	.navbar-brand {
		color: $primary;
	}
	
	@include media-breakpoint-up(md) {
		.section {
			@include make-col(4, $grid-columns);
			
			+ .section {
				margin-top: 0 !important;
			}
		}
	}
}

.footer {
	padding-top: $navbar-padding-y;
	padding-bottom: $navbar-padding-y;
	color: $white;
	background-color: $primary;
}

.credit {
	margin-top: $navbar-padding-y;
	text-align: center;
}

.pagetop {
	position: fixed;
	bottom: 0;
	right: 0;
	margin: $grid-gutter-width;
	z-index: $zindex-fixed;
	
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		text-decoration: none;
		color: $white;
		background-color: $primary;
		@include border-radius($border-radius-pill);
	}
}

// Content

.content {
	padding-top: $grid-gutter-width;
	padding-bottom: $grid-gutter-width;
}

.main,
.side {
	@include make-col-ready();
}

.main {
	margin-bottom: $grid-gutter-width;
}

@include media-breakpoint-up(md) {
	.main {
		margin-bottom: 0;
		@include make-col(8, $grid-columns);
	}
	
	.side {
		@include make-col(4, $grid-columns);
	}
}

@include media-breakpoint-up(xl) {
	.main {
		@include make-col(9, $grid-columns);
	}
	
	.side {
		@include make-col(3, $grid-columns);
	}
}
